<template>
<div>
  <br>
  <Steps/>
</div>
</template>

<script>
import Steps from '@/components/ProgramFlow/Steps'
export default {
  name: 'Home',
  components: {
    Steps
  }
}
</script>