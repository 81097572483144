<template>
<div>    
    <v-list>
        <v-list-item-title v-show="group.text[language] && group.text[language].length>0">
            {{group.text[language]}}
        </v-list-item-title>
        <v-radio-group v-model="form.value" class="pa-0">
            <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id" style="padding: 0px;">
                <v-list-item-content style="padding: 15px;">
                    <v-radio dense v-show="choice.display" :disabled="question.display===false || choice.disabled===true" :value="choice.value ? choice.value : choice.id" :label="choice.text[language]">
                        <template v-slot:label>
                            <span v-html="choice.text[language]"/>
                        </template>
                    </v-radio>
                    <other v-if="form.value==choice.value && choice.other" v-model="form.others[choice.id]" :label="choice.other_text[language]"/>
                </v-list-item-content>
            </v-list-item>
        </v-radio-group>     
    </v-list>
</div>
</template>

<script>
import other from './other'
export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        question: {
            type: Object,
            required: true
        },
        group: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        other
    },
    created: function(){
        this.form = this.$attrs.value
    },
    data: function(){
        return {
            form: {}
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.$emit('input',this.form)
            }
        }
    }
}
</script>

<style>

</style>