<template>
  <div>
    <v-data-table
        :headers="patient_headers"
        :items="patients"
        hide-default-footer
        disable-pagination
    >
        <!-- hide-default-header -->
        <!-- <template v-slot:header="{ props }">
            <thead class="v-data-table-header">
                <tr>
                    <th v-for="head in props.headers"
                        :key="head.value" class="text-start sortable">
                        <str :index="head.label"/>
                    </th>
                </tr>
            </thead>
        </template> -->

        <template v-slot:item.second_visit="{item}">
            <v-btn v-if="item.second_visit===null" style="margin-right: 15px;" color="primary" @click="$emit('select_patient',item)" small>
                 <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span>{{item.second_visit}}</span>
        </template>

        <template v-slot:item.third_visit="{item}">
            <v-btn v-if="item.second_visit!=null && item.third_visit===null" style="margin-right: 15px;" color="primary" @click="$emit('select_patient',item)" small>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <span>{{item.third_visit}}</span>
        </template>

        <template v-slot:item.edit="{item}">
            <!-- <v-btn v-if="item.second_visit===null" style="margin-right: 15px;" color="primary" @click="$emit('select_patient',item)" small>2nd Visit</v-btn> -->
            <v-btn icon color="primary" @click="editDate(item)" small>
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </template>

        <template v-slot:item.disable="{item}">
            <v-btn icon color="primary" @click="disable(item)" small>
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </template>
    </v-data-table>
    
    <v-dialog v-model="ui.edit_date">
        <v-card v-if="ui.edit_date">
            <v-card-title>
                <str index="patient_manager.current_patients.edit_dates.title"/>
            </v-card-title>
            <v-card-subtitle>
                Patient ID: {{selected_patient.patient_id}}
            </v-card-subtitle>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col v-if="selected_patient.first_visit!=null">
                            <h3><str index="patient_manager.common.first_visit"/></h3>
                            <v-date-picker v-model="selected_patient.first_visit" :max="calculateMax()"/>

                        </v-col>

                        <v-col v-if="selected_patient.second_visit!=null">
                            <h3><str index="patient_manager.common.second_visit"/></h3>
                            <v-date-picker v-model="selected_patient.second_visit" :min="calculateMin(selected_patient.first_visit)" :max="calculateMax(selected_patient.first_visit)"/>
                        </v-col>

                        <v-col v-if="selected_patient.third_visit!=null">
                            <h3><str index="patient_manager.common.third_visit"/></h3>
                            <v-date-picker v-model="selected_patient.third_visit" :min="calculateMin(selected_patient.second_visit)" :max="calculateMax(selected_patient.second_visit)"/>
                        </v-col>

                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="themed" :disabled="invalidDates" @click="update">
                    <str index="buttons.update"/>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn class="themed" @click="ui.edit_date=false">
                    <str index="buttons.cancel"/>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-snackbar v-model="ui.snackbar.display">
        <str index="ui.snackbar.message"/>
        <template v-slot:action="{ attrs }">
            <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="ui.snackbar.display = false"
            >
            <str index="buttons.close"/>
            </v-btn>
        </template>
    </v-snackbar>
  </div>
</template>

<script>
import {visit_rules} from './visit_rules.js'
export default {
    props: {
        user_id: {
            type: String,
            required: true
        }
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            ready: false,
            ui: {
                edit_date: false,
                snackbar: {
                    display: false,
                    message: null
                }
            },
            selected_patient: null,
            patients: []
        }
    },
    methods: {
        init: async function(){
            await this.getPatients()
            await this.getAssessments()
            this.ready = true
        },
        getPatients: async function(){
            let user_id = this.user_id
            let response
            try {
                response = await this.sendRequest('get',`/api/patients/get_all/${user_id}`)
                let patients = response.data
                for(let i=0; i<patients.length; i++){
                    patients[i].updated = new Date(patients[i].updated)
                    if(patients[i].first_visit!=null){patients[i].first_visit = patients[i].first_visit.split('T')[0]}
                    if(patients[i].second_visit!=null){patients[i].second_visit = patients[i].second_visit.split('T')[0]}
                    if(patients[i].third_visit!=null){patients[i].third_visit = patients[i].third_visit.split('T')[0]}
                }
                this.patients = patients
                if(this.patients.length==0){
                    this.$emit('noPatients')
                }else{
                    this.$emit('setPatients', patients)
                }
            } catch (error) {
                this.ui.snackbar.display = true
                this.ui.snackbar.message = this.messageIndex.errors.getPatients
            }

        },
        getAssessments: async function(){
            let response = await this.sendRequest('get',`/api/program/assessments/${this.user_id}`)
            this.$emit('setAssessments',response.data)
        },        
        editDate: function(patient){
            this.selected_patient = patient
            this.ui.edit_date = true
        },
        update: async function(){
            let patient = this.selected_patient
            let user_id = this.user_id
            let updatePackage = {patient, user_id}
            let response
            try {
                response = await this.sendRequest('patch',`/api/patients/update_visits`,updatePackage)                
                this.ui.edit_date = false
            } catch (error) {
                console.error(error)
            }
            console.log('update',{
                updatePackage,
                response
            })
        },
        disable: async function(patient){
            if(confirm(`${this.$store.getters.labels.patient_manager.messages.confirm_removal._text[this.language]}: ${patient.patient_id}`)){
                let response
                try {
                    response = await this.sendRequest('delete',`/api/patients/delete/${patient.patient_id}/${this.user_id}`)
                    await this.init()
                } catch (error) {
                    this.ui.snackbar.message = this.messageIndex.errors.delete_failed
                    this.ui.snackbar.display = true                
                }
            }
        },
        calculateMin: function(input){
            return visit_rules.calculateMin(input)
        },
        calculateMax: function(input){
            return visit_rules.calculateMax(input)
        }   
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        messageIndex: function(){
            return {
                errors: {
                    delete_failed: 'current_patients.errors.delete_failed',
                    getPatients: 'current_patients.errors.getPatients'
                }
            }
        },
        patient_headers: function(){

            return [
            {
                // text: 'Patient ID',
                // label: 'patient_manager.current_patients.table_col_patient_id',
                text: this.$store.getters.labels.patient_manager.current_patients.table_col_patient_id._text[this.language],
                align: 'start',
                sortable: false,
                value: 'patient_id',
            },
            { 
                // text: 'Updated', 
                // label: 'patient_manager.current_patients.table_col_updated', 
                text: this.$store.getters.labels.patient_manager.current_patients.table_col_updated._text[this.language], 
                value: 'updated'
            },
            { 
                // text: 'First Visit', 
                // label: 'patient_manager.current_patients.table_col_first_visit', 
                text: this.$store.getters.labels.patient_manager.current_patients.table_col_first_visit._text[this.language], 
                value: 'first_visit' 
            },
            { 
                // text: 'Second Visit', 
                // label: 'patient_manager.current_patients.table_col_second_visit',
                text: this.$store.getters.labels.patient_manager.current_patients.table_col_second_visit._text[this.language],
                 value: 'second_visit' 
            },
            { 
                // text: 'Third Visit', 
                // label: 'patient_manager.current_patients.table_col_third_visit',
                text: this.$store.getters.labels.patient_manager.current_patients.table_col_third_visit._text[this.language],
                 value: 'third_visit' 
            },
            { 
                // text: 'Edit', 
                // label: 'patient_manager.current_patients.table_col_edit', 
                text: this.$store.getters.labels.patient_manager.current_patients.table_col_edit._text[this.language], 
                value: 'edit', 
                sortable: false 
            },
            { 
                // text: 'Remove', 
                // label: 'patient_manager.current_patients.table_col_remove', 
                text: this.$store.getters.labels.patient_manager.current_patients.table_col_remove._text[this.language], 
                value: 'disable', 
                sortable: false
                }
            ]            
        },
        invalidDates: function(){
            let first = new Date(this.selected_patient.first_visit)
            let second = this.selected_patient.second_visit ? new Date(this.selected_patient.second_visit) : null
            let third = this.selected_patient.third_visit ? new Date(this.selected_patient.third_visit) : null
            let invalid = ((first>second) && second!=null) || (second>third) && third!=null
            if(!invalid && first && second){
                let Min = new Date(this.calculateMin(this.selected_patient.first_visit))
                let Max = new Date(this.calculateMax(this.selected_patient.first_visit))
                invalid = second<Min || second>Max
            }
            if(!invalid && first && second && third){
                let Min = new Date(this.calculateMin(this.selected_patient.second_visit))
                let Max = new Date(this.calculateMax(this.selected_patient.second_visit))
                invalid = third<Min || third>Max
            }
            // console.log({
            //     first_visit: this.selected_patient.first_visit,
            //     second_visit: this.selected_patient.second_visit,
            //     first,
            //     second,
            //     invalid,
            //     test: (first>second)
            // })

            return invalid
        }
    }
}
</script>

<style>

</style>