<template>
    
    <v-menu v-if="$store.getters.exposeLabels" :open-on-hover="$store.getters.exposeLabels" :offset-x="true" top max-width="60">
        <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" v-html="output" :class="[missing ? 'missing' : '', $store.getters.exposeLabels ? 'exposed' : '']"/> 
        </template>
        <v-btn @click="edit"><v-icon small>mdi-pencil</v-icon></v-btn>
    </v-menu>
    <span v-else v-html="output" :class="missing ? 'missing' : ''"/> 
</template>

<script>
export default {
    name: 'Str',
    props: {
        index: {
            type: String,
            required: true
        },
        language: {
            type: String,
            required: false
        }
    },
    data: function(){
        return {
            missing: false
        }
    },
    methods: {
        edit: function(){
            if(!this.missing){
                this.$router.push({name:'editLabel',params: {index:this.index.split('._text')[0]}})
            }else{
                this.$router.push({name:'newLabel'})
            }
        }
    },
    computed: {
        labels: function(){
            return this.$store.getters.labels
        },
        lang: function(){
            return this.language ? this.language : this.$store.getters.language
        },
        output: function(){
            let labels = this.labels
            let language = this.lang
            let index = this.index
            let targets = index.split('.')
            if(targets[targets.length-1]!='_text'){
                targets.push('_text')
            }
            let target = ''
            for(let i=0; i<targets.length; i++){
                target += i==0 ? targets[i] : `?.${targets[i]}`
            }
            let targetIndex = `labels?.${target}`
            this.missing = false

            if(labels===undefined){
                return "...loading..."
            }else if(eval(targetIndex+"['"+language+"']")){
                return eval(targetIndex+"['"+language+"']")
            // }else if(eval("labels."+index+" && labels."+index+"['en-ca']")){
            //     return eval("labels."+index+" && labels."+index+"['en-ca']")
            }else{
                this.missing = true
                return `MISSING [${language}]: ${index}`
            }
        }
    }
}
</script>

<style scoped>
.exposed{
    color: purple !important;
    font-style: italic;
}

.missing{
    color: red !important;
    font-style: italic;
}
</style>