<template>
<div>    
    <v-container fluid>
        <v-layout row wrap>
            <v-flex xs6 md5>
                <div v-html="group.text[language]"></div>
                <div :class="['available_options_wrapper', 'mt-8']">
                    <h3 align='center'>
                        options
                    </h3>
                    <template v-for="(choice, choiceIndex) in group.choices">
                        <v-container :key="'ranking_item_'+choice.id+'_'+question.id" py-0>
                            <v-layout row wrap fill-height>
                                <v-flex xs2 md1>
                                    <div
                                        :class="[$style.alphabeticalOrderWrapper, $style.available]">
                                        <div :class="[$style.itemNumber,{[$style.ranked]:(form.value.includes(choice.value) || form.value.length>=group.requirements.max)}]">
                                            {{String.fromCharCode(94 + (choiceIndex+3))}}
                                        </div>
                                    </div>
                                </v-flex>
                                <v-flex xs10 md11>
                                    <div 
                                        :class="[$style.list_item,{[$style.ranked]:(form.value.includes(choice.value) || form.value.length>=group.requirements.max)}]" 
                                        @click="rankAnswer(choice.value)">
                                        <div v-html="choice.text[language]" :class="$style.rankingSelections"></div>
                                    </div>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </template>
                </div>    
            </v-flex>
            <v-flex xs2 class="hidden-sm-and-down">
                <div :class="[$style.arrowSectionWrapper, 'mt-10']">
                    <div :class="$style.arrowTopSection">
                    </div>
                    <div :class="$style.arrowIconSection">
                        <v-icon color="#cecdce">
                            mdi-chevron-right
                        </v-icon>
                    </div>
                    <div :class="$style.arrowTopSection">
                    </div>
                </div>
            </v-flex>
            <v-flex xs6 md5> 
                <div v-html="group.text[language]"></div>
                <div :class="$style.selectionOptionsLabelWrapper">
                    <div :class="$style.selectionOptionsLabelInnerWrapper">
                        <v-icon x-small color="#d0eaee">
                            mdi-circle
                        </v-icon>
                        <span class='ml-2'>
                            <!-- <str index="survey > question > ranking > required" /> -->
                                required
                        </span>
                    </div>

                    <div :class="$style.selectionOptionsLabelInnerWrapper">
                        <v-icon x-small color="#dedede">
                            mdi-circle
                        </v-icon>
                        <span class='ml-2'>
                            <!-- <str index="survey > question > ranking > optional" /> -->
                                optional
                        </span>
                    </div>
                </div>
                <div :class="['selection_options_wrapper', 'mt-2']">
                    <h3 align='center'>
                        <!-- <str :index="'survey > question > ranking > selected'" /> -->
                            ranked
                    </h3>
                </div>
                <template v-for="(n, index) in parseInt(group.requirements ? group.requirements.max : group.choices.length)">
                    <v-container :key="'ranking_items_'+group.id+'_'+n" py-0> 
                        <v-layout row wrap fill-height>
                            <v-flex xs2 md1>
                                <div :class="$style.numericalOrderWrapper">
                                    <div :class="[$style.itemNumber,{[$style.required]:(index+1)<=(group.requirements ? group.requirements.min : group.choices.length)},{[$style.answered]:form.value[index]}]">
                                        {{index + 1}}
                                    </div>
                                </div>
                            </v-flex>
                            <v-flex xs10 md11>
                                    <div :class="[$style.answer_item,{[$style.required]:(index+1)<=(group.requirements ? group.requirements.min : group.choices.length)},{[$style.answered]:form.value[index]}]">

                                        <div v-if="form.value[index]" :class="$style.rankingSelections">
                                            <div v-if="valueMap[form.value[index]].other" class="otherInput" style="position: relative; top: -1px; over-flow: hidden;">
                                                <v-btn icon small @click="removeRankedAnswer(form.value[index])">
                                                    <v-icon color="white">
                                                        mdi-chevron-left
                                                    </v-icon>
                                                </v-btn>
                                                <v-text-field solo dense flat style="display: inline-block; width:85%;" height="23" clearable v-model="form.others[valueMap[form.value[index]].id]" :label="valueMap[form.value[index]].other_text[language]"/>
                                            </div>
                                            <div v-else @click="removeRankedAnswer(form.value[index])" v-html="valueMap[form.value[index]].text[language]"/>
                                        </div>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </template>
            </v-flex>


            <!-- <v-flex xs12 style="text-align: center:" class='mt-10'>
                <center>
                    <completionBar
                        :total="question.value!=null && question.value[group.id] ? Math.round((question.value[group.id].length/parseInt(group.type_config.Ranking.min,10))*100) : 0"
                        :disabled-overflow="true"
                        :displayNumber="true" />
                </center>
            </v-flex>
            <v-flex xs12 style="text-align: right;">
                <v-btn 
                    color='#009dad' 
                    rounded 
                    dark
                    depressed
                    @click="ranking.ranked[group.id] = [];">
                    RESET
                </v-btn>
            </v-flex> -->
        </v-layout>
    </v-container>
    
</div>
</template>

<script>
import other from './other'
export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        question: {
            type: Object,
            required: true
        },
        group: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        other
    },
    created: function(){
        this.form = this.$attrs.value
        // this.form.others = {}
        // for(let c=0; c<this.group.choices.length; c++){
        //     let choice = this.group.choices[c]
        //     if(choice.other){
        //         this.form.others[choice.id] = null
        //     }
        // }
    },
    data: function(){
        return {
            form: {},
            justify: ["start", "end", "center", "space-between", "space-around"]
        }
    },
    methods: {
        rankAnswer: function(choiceIndex){
            this.form.value.push(choiceIndex)
        },
        removeRankedAnswer: function(value){
            let index = this.form.value.indexOf(value)
            this.form.value.splice(index, 1)
        }
    },
    computed: {
        valueMap: function(){
            let array = this.group.choices
            let output = {}
            for(let c=0; c<array.length; c++){
                let item = array[c]
                output[item.value] = item
            }
            return output
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.$emit('input',this.form)
            }
        }
    }
}
</script>

<style module>
    .vCard {
        padding: 19px 140px;
        width: 100%;
        margin-bottom: 20px;
    }

    .question {
        border: 1px solid slategray;
        border-radius: 3px;
        margin: 25px;
        padding: 15px;
    }

    .questions {
        font-size: 18px;
        margin: 0px 0px 20px;
        color: black;
    }

    .list_item {
        border: 1px solid #5d6771;
        border-radius: 3px;
        margin: 10px 10px 10px 0;
        padding: 2px 15px;
        cursor: pointer;
        background: #5d6771;
        color: white;
        box-shadow: 2px 1px 3px 0px #c0c0c0;
    }

    .list_item:hover {
        background-color: #778491;
    }

    .locked {
        border-color: whitesmoke;
        color: lightgrey;
    }

    .complete {
        border-color: #0588EF;
        border-width: 3px;
        color: #0588EF;
        font-weight: bold;
    }

    .debug {
        display: inline-block;
        margin: 5px;
        color: darkred;
    }

    .alphabeticalOrderWrapper, .numericalOrderWrapper {
        display: flex; 
        align-items: center; 
        justify-content: center; 
        height: 100%;
    }

    .alphabeticalOrder {
        display: flex; 
        align-items: center; 
        justify-content: center; 
        background-color: #5d6771; 
        color: white; 
        border-radius: 50%; 
        width: 25px; 
        height: 25px;
    }

    .alphabeticalOrderExisted {
        background-color: #b5b4b6;
        color: #b5b4b6
    }

    .list_item_existed {
        background-color: #b5b4b6;
        color: #b5b4b6;
        cursor: initial;
        pointer-events: none;
        border: 1px solid #b5b4b6;
        -webkit-user-select: none; /* Chrome/Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */
        -o-user-select: none;
        user-select: none;
    }

    .list_item_max {
        pointer-events: none;
        -webkit-user-select: none; /* Chrome/Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */
        -o-user-select: none;
        user-select: none;
    }

    .alphabeticalOrderAnswer {
        display: flex; 
        align-items: center; 
        justify-content: center; 
        background-color: #b5b4b6; 
        color: #b5b4b6; 
        border-radius: 50%; 
        width: 25px; 
        height: 25px;
    }

    .itemNumber{
        display: flex; 
        align-items: center; 
        justify-content: center; 
        background-color: #b5b4b6; 
        color: white; 
        border-radius: 50%; 
        width: 25px; 
        height: 25px;
    }

    .alphabeticalOrderWrapper .itemNumber{
        background-color: #5D6771;
    }    

    .alphabeticalOrderWrapper .itemNumber.ranked{
        background-color: #B5B4B6;
    }    

    .ranked{
        background-color: #B5B4B6;
        color: transparent;
        pointer-events: none;
    }    

    .alphabeticalOrderAnswerRequiredNonExisted,.answer_item_required_non_existed {
        background-color: #d0eaee !important;
        color: white;
    }

    .alphabeticalOrderAnswerOptionalNonExisted, .answer_item_optional_non_existed {
        background-color: #dedede !important;;
        color: white;
    }

    .alphabeticalOrderAnswerExisted {
        background-color: #5d6771; 
        color: white; 
    }

    .answer_item {
        background-color: #b5b4b6;
        color: #b5b4b6;
        cursor: initial;
        pointer-events: none;
        border: 1px solid #b5b4b6;
        -webkit-user-select: none; /* Chrome/Safari */        
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+ */
        -o-user-select: none;
        user-select: none;
        border-radius: 3px;
        margin: 10px 10px 10px 0;
        padding: 2px 15px;
        box-shadow: 2px 1px 3px 0px #c0c0c0;
        height: 28px;
    }

    .required{
        background-color: #D0EAEE;
    }

    .answered{
        background-color: #5D6771;
        color: white;
        pointer-events: auto;
    }

    .answer_item_existed {
        background-color: #5d6771;
        color: white;
        cursor: pointer;
        pointer-events: initial;
        border: 1px solid #5d6771;
    }

    .arrowSectionWrapper {
        height: 100%; 
        display: flex; 
        flex-direction: column; 
        align-items: center; 
        justify-content: center;
    }

    .arrowTopSection {
        border: 1px dashed #cecdce; 
        height: 35%; 
        width: 0%;
    }

    .arrowIconSection {
        border-radius: 50%; 
        border: 1px dashed #cecdce; 
        width: 45px; 
        height: 45px; 
        display: flex; 
        align-items: center; 
        justify-content: center;
    }

    .rankingInputLabel p{
        margin-bottom: 0px !important;
    }
    
    .rankingSelections p {
        margin-bottom: 0px !important;
    }

    .selectionOptionsLabelWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
    }

    .selectionOptionsLabelInnerWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 10px;
    }

    @media only screen and (max-width: 1240px) {
        .vCard {
            padding: 0 100px;
        }
    }

    @media only screen and (max-width: 1145px) {
        .vCard {
            padding: 0 80px;
        }
    }

    @media only screen and (max-width: 1105px) {
        .vCard {
            padding: 0 30px;
        }
    }

    @media only screen and (max-width: 1000px) {
        .vCard {
            padding: 0 10px;
        }
    }

    @media only screen and (max-width: 980px) {

        .vCard {
            margin: 0px !important;
            width: 100%;
            padding: 0px 10px;
        }

        .questions {
            width: 95%;
            margin: 0px !important;
        }

    }
</style>

<style>
.otherInput .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
}
</style>