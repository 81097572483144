const visit_rules = new function(){
    function formatDate(input){
        let year = input.getFullYear()
        let month = input.getMonth()+1
        month = month<10 ? '0'+month : month
        let date = input.getDate()
        date = date<10 ? '0'+date : date
        
        return `${year}-${month}-${date}`
    }

    this.calculateMin = function(input){
        let inputObject = input.split('-')
        for(let i=0; i<inputObject.length; i++){
            inputObject[i] = parseInt(inputObject[i],10)
            if(i==1){
                inputObject[i]--
            }
        }
        let output = new Date(inputObject[0], inputObject[1], inputObject[2], 0, 0)
        output.setMonth(output.getMonth()+3)
        let today = new Date()
        output = output<=today ? output : today
        
        let result = formatDate(output)
        return result
    }

    this.calculateMax = function(input){
        if(!input){
            let output = new Date()
            output.setMonth(output.getMonth()-3)
            let result = formatDate(output)
            return result
        }else{
            let inputObject = input.split('-')
            for(let i=0; i<inputObject.length; i++){
                inputObject[i] = parseInt(inputObject[i],10)
                if(i==1){
                    inputObject[i]--
                }
            }
            let output = new Date(inputObject[0], inputObject[1], inputObject[2], 0, 0)
            output.setMonth(output.getMonth()+8)
            let today = new Date()
            output = output<today ? output : today
        
            let result = formatDate(output)
            return result

        }
    }

    this.today = function(){
        let output = new Date()
        let result = formatDate(output)
        return result

    }
}

module.exports = {
    visit_rules  
}