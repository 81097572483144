<template>
    <div>                
        <v-stepper v-model="step.selection" vertical>
            <v-stepper-step :editable="$store.getters.debug || step.selection>1" :step="1">
                1 - <str index="patient_manager.tabs.steps.patient_identification"/>
            </v-stepper-step>
                <v-stepper-content :step="1">
                    <v-card flat>
                        <v-card-text>
                            <v-form @submit.prevent="completeStep(1)" ref="step_1" v-model="valid_step[1]">
                                <v-text-field :disabled="patient!=undefined" v-model="forms.patient.patient_id" @keyup="forms.patient.patient_id = forms.patient.patient_id.toUpperCase()" required :rules="rules.required">
                                    <template v-slot:label>
                                        <str index="patient_manager.forms.labels.patient_identification"/>
                                    </template>
                                </v-text-field>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="!valid_step[1]" class="themed" @click="checkPatientID">
                                <str index="buttons.continue"/>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                    
                </v-stepper-content>

            <v-stepper-step :editable="$store.getters.debug || step.selection>2" :step="2">
                2 - <str :index="`patient_manager.tabs.steps.${visitType}_date`"/>
                <small v-if="visitType=='second_visit'"><br>
                    <str :index="`patient_manager.tabs.steps.second_date_subtitle`"/>
                </small>
                <small v-else-if="visitType=='third_visit'"><br>
                    <str :index="`patient_manager.tabs.steps.third_date_subtitle`"/>
                </small>
            </v-stepper-step>
                <v-stepper-content :step="2">
                    <v-card flat>
                        <v-card-text>
                            <v-form  @submit.prevent ref="step_2" v-model="valid_step[2]" style="max-width: 500px;">
                                <v-date-picker v-if="patient==undefined" v-model="forms.patient.first_visit" full-width :max="calculateMax()" :landscape="$store.getters.width>=800"></v-date-picker>
                                <template v-if="visitType=='second_visit'">
                                    <str index="patient_manager.common.first_visit"/>: {{forms.patient.first_visit}}
                                    <v-date-picker v-model="forms.patient.second_visit" :disabled="calculateMin(forms.patient.first_visit) == calculateMax(forms.patient.first_visit)" :min="calculateMin(forms.patient.first_visit)" :max="calculateMax(forms.patient.first_visit)" full-width :landscape="$store.getters.width>=800"></v-date-picker>
                                </template>
                                <template v-else-if="visitType=='third_visit'">
                                    <str index="patient_manager.common.first_visit"/>: {{forms.patient.first_visit}}
                                    <br>
                                    <str index="patient_manager.common.second_visit"/>: {{forms.patient.second_visit}}
                                    <v-date-picker v-model="forms.patient.third_visit" :disabled="calculateMin(forms.patient.second_visit) == calculateMax(forms.patient.second_visit)" :min="calculateMin(forms.patient.second_visit)" :max="today()" full-width :landscape="$store.getters.width>=800"></v-date-picker>
                                </template>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :disabled="(visitType=='first_visit' && forms.patient.first_visit===null) || (visitType=='second_visit' && forms.patient.second_visit===null) || (visitType=='third_visit' && forms.patient.third_visit===null)" class="themed" @click="step.selection++">
                                <str index="buttons.continue"/>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>

            <v-stepper-step :editable="$store.getters.debug" :step="3">
                3 - <str index="patient_manager.tabs.steps.patient_assessment"/>
            </v-stepper-step>
                <v-stepper-content :style="$store.getters.width<=800 ? {'padding':'0px','margin-left':'0px'} : {}" :step="3">
                    <v-card :style="$store.getters.width<=800 ? {'padding':'0px'} : {}">
                        <v-card-title>
                            <str index="patient_manager.tabs.steps.patient_identification"/>: {{forms.patient.patient_id}}
                        </v-card-title>
                        <v-card-subtitle>
                            <str index="patient_manager.common.first_visit"/>: {{forms.patient.first_visit}}
                            <template v-if="patient!=undefined">
                                <br>
                                <str index="patient_manager.common.second_visit"/>: {{forms.patient.second_visit}}
                            </template>
                        </v-card-subtitle>
                        <v-card-text  :style="$store.getters.width<=800 ? {'padding':'0px'} : {}">
                            <StepHeader>
                                <template v-slot:stepHeader>
                                    <str index="patient_manager.tabs.steps.patient_assessment"/>
                                </template>
                            </StepHeader>
                            <br>
                            <PatientAssessment :visitType="visitType" :busy="busy" @submit="submit"/>
                        </v-card-text>
                    </v-card>
                </v-stepper-content>

        </v-stepper>

        <v-snackbar v-model="ui.snackbar.display">
            <str v-if="ui.snackbar.display" :index="ui.snackbar.message"/>
            <template v-slot:action="{ attrs }">
                <v-btn
                color="pink"
                text
                v-bind="attrs"
                @click="ui.snackbar.display = false"
                >
                <str index="buttons.close"/>
                </v-btn>
            </template>
        </v-snackbar>
        
    </div>
</template>

<script>
import validations from '@/plugins/validations.js'
import PatientAssessment from './patient_assessment'
import StepHeader from './step_header.vue'
import {visit_rules} from './visit_rules.js'
export default {
    props: {
        user_id: {
            type: String,
            required: true
        },
        visitType: {
            type: String,
            required: true
        },
        patient: {
            type: Object,
            required: false
        }
    },
    components: {
        PatientAssessment,
        StepHeader
    },
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)
        if(this.patient!=undefined){
            for(let key in this.patient){
                this.forms.patient[key] = this.patient[key]
            }
            this.step.selection = 2
        }
    },
    data: function(){
        return {
            busy: false,
            valid_step: {
                1: false,
                2: false
            },
            step: {
                selection: 1
            },
            forms: {
                patient: {
                    patient_id: null,
                    first_visit: null,
                    second_visit: null,
                    third_visit: null   
                }
            },
            ui: {
                snackbar: {
                    display: false,
                    message: null
                }
            }
        }
    },
    methods: {
        completeStep(num){
            switch(num){
                case 1:
                    if(this.valid_step[1]){
                        this.checkPatientID()
                    }
                break;
            }
        },
        checkPatientID: async function(){
            let response = await this.sendRequest('get',`/api/patients/get_patient/${this.forms.patient.patient_id}/${this.user_id}`)
            console.log(response)
            if(this.visitType=='first_visit' && response.data.length==0){
                console.log('new patient_id confirmed')
                this.step.selection++
            }else if(this.visitType=='second_visit' && response.data.length>0){
                console.log('existing patient_id confirmed')
                this.step.selection++
            }else{
                this.ui.snackbar.message = this.messageIndex.errors.id_already_used
                this.ui.snackbar.display = true
                console.error('Invalid Patient ID',{
                    visitType: this.visitType,
                    response
                })
            }
        },
        submit: async function(data){
            this.busy = true
            let savePackage = data
            savePackage.patient = this.forms.patient
            let response = await this.sendRequest('post',`/api/patients/add_visit/${savePackage.question_set_id}/${savePackage.version}`, {answers: savePackage.patient_assessment, patient: savePackage.patient, visitType: this.visitType, user_id: this.user_id})
            this.busy = false
            this.$emit('addVisit', {savePackage, response})
        },
        calculateMin: function(input){
            let output = visit_rules.calculateMin(input)
            return output
        },
        calculateMax: function(input){
            let output = visit_rules.calculateMax(input)
            return output
        },
        today: function(){
            return visit_rules.today()
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        messageIndex: function(){
            return {
                errors: {
                    id_already_used: 'patient_manager.errors.id_already_used'
                }
            }
        }
    }
}
</script>

<style>

</style>