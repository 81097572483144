<template>
  <div class="banner">
    <div class="tab-content" :style="{'background-image':`URL(${tabList[tabSelection].background})`}">
      <component v-bind:is="tabList[tabSelection].component" :background="tabList[tabSelection].background" :body="tabList[tabSelection].body"/> 
    </div>

    <div class="tab-container">
      <div class="tab-button" :class="[{'tab-button-selected':tabIndex==tabSelection}]" v-for="(tab, tabIndex) in tabList" :key="'tab_'+tabIndex" @click="selectTab(tabIndex)">
        <div class="tab-button-content">
          <v-icon>{{tab.icon}}</v-icon>
          <str :index="tab.label"/>
        </div>
        <div v-if="tabIndex==tabSelection" class="square"/>
      </div>
    </div>
  </div>
</template>

<script>
import Overview from './lib/overview.vue'
import InclusionCriteria from './lib/inclusionCriteria.vue'
import Objectives from './lib/objectives.vue'
import Resources from './lib/resources.vue'
import bg_overview from './assets/overview.png'
import bg_objectives from './assets/objectives.png'
import bg_resources from './assets/resources.png'
import bg_inclusion_criteria from './assets/inclusion_criteria.png'

export default {
  components: {
    Overview,
    InclusionCriteria,
    Objectives,
    Resources
  },
  data: function(){
    return {
      tabSelection: 0
    }
  },
  methods: {
    selectTab: function(index){
      this.tabSelection = index
    }
  },
  computed: {
    images: function(){
      return {
        bg_overview,
        bg_objectives,
        bg_resources,
        bg_inclusion_criteria
      }
    },
    tabList: function(){
      let list =[
        {
          label: 'banner.tabs.overview.title',
          body: 'banner.tabs.overview.body',
          icon: 'mdi-information-outline',
          component: 'Overview',
          background: bg_overview
        },
        {
          label: 'banner.tabs.objectives.title',
          body: 'banner.tabs.objectives.body',
          icon: 'mdi-crosshairs-gps',
          component: 'Objectives',
          background: bg_objectives
        },
        {
          label: 'banner.tabs.inclusion_criteria.title',
          body: 'banner.tabs.inclusion_criteria.body',
          icon: 'mdi-check',
          component: 'InclusionCriteria',
          background: bg_inclusion_criteria
        },
        {
          label: 'banner.tabs.resources.title',
          body: 'banner.tabs.resources.body',
          icon: 'mdi-file-document-outline',
          component: 'Resources',
          background: bg_resources
        }
      ]

      return list
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
    width: 100%;
    min-height: 220px;
    // border: 1px solid grey;
}

.tab-content{
  padding: 25px;
  width: 100%;
  min-height: 470px;
  background-size: cover;
}

.tab-container{
  width: 100%;
  text-align: center;
}

.tab-button{
  display: inline-block;
  width: 250px;
  height: 50px;
  border: 3px solid transparent;
  margin: 2px 5px 0px 5px;
  background-color: $primary;
  color: white;
  position: relative;
  cursor: pointer;
}
.tab-button .v-icon{
  color: white;
  margin: 0px 5px 5px 0px;
}

.tab-button-selected{
  background-color: white;
  border-color: $primary;
  border-width: 3px;
  color: $primary;
}
.tab-button-selected .v-icon{
  color: $primary;
}

.tab-button-content{
  text-align: center;
  position: relative;
  padding-top: 8px;
}
.tab-button-selected .tab-button-content{
  background-color: white;
}

.square{
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translate(-50%,0);
    width: 0; 
    height: 0; 
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;  
    border-bottom: 12px solid $primary;
}

@media only screen and (max-width: 1042px){
  .square{
    display: none;
  }
}
</style>

<style>
.banner ul {
  margin: 0.75em 0;
  padding: 0 1em;
  list-style: none;
  line-height: 15px;
}
.banner ul li{
  line-height: 20px;
}
.banner li::before { 
  content: "";
  border-color: transparent #03A0E3;
  border-style: solid;
  border-width: 0.35em 0 0.35em 0.45em;
  display: block;
  height: 0;
  width: 0;
  left: -1em;
  top: 1.1em;
  position: relative;
}

</style>