<template/>

<script>
export default {
    created: function(){
        this.init()
    },
    methods: {
        init: async function(){
            let hash = this.hash
            let response = await this.sendRequest('get',`/api/participant/${hash}`)
            if(response.data.record){
                this.$emit('participant',response.data.record)
            }
        }
    },
    computed: {
        hash: function(){
            return this.$route.params && this.$route.params.hash ? this.$route.params.hash : null
        }
    }
}
</script>

<style>

</style>