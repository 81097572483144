<template>
  <div>
    <div style="max-width: 450px; margin: 0 auto;">
        <v-tabs
            grow
            v-model="tab.selection"
            icons-and-text
        >
            <v-tab :key="'current_patients'">
                <str index="patient_manager.tabs.title.add_patient"/>
                <v-icon>mdi-clipboard-text-outline</v-icon>
            </v-tab>
            <v-tab :key="'first_visit'">
                <str index="patient_manager.tabs.title.first_visit"/>
                <v-icon>mdi-account-plus</v-icon>
            </v-tab>
            <v-tab v-show="tab.selection==2"  :disabled="tab.selection!=2" :key="'second_visit'">
                <str index="patient_manager.tabs.title.second_visit"/>
                <v-icon>mdi-calendar-plus</v-icon>
            </v-tab>
            <v-tab v-show="tab.selection==3"  :disabled="tab.selection!=3" :key="'third_visit'">
                <str index="patient_manager.tabs.title.third_visit"/>
                <v-icon>mdi-calendar-plus</v-icon>
            </v-tab>

        </v-tabs>

    </div>

    <v-tabs-items v-model="tab.selection">
        <v-tab-item :key="'current_patients'">
            <CurrentPatients v-if="tab.selection==0" :user_id="user_id" @setPatients="setPatients"  @setAssessments="setAssessments" @select_patient="select_patient" @noPatients="tab.selection=1"/>
        </v-tab-item>

        <v-tab-item :key="'first_visit'">
            <AddVisit v-if="tab.selection==1" :visitType="'first_visit'" :user_id="user_id" @addVisit="addVisit" style="text-align: left;"/>
        </v-tab-item>

        <v-tab-item :key="'second_visit'">
            <AddVisit v-if="tab.selection==2 && patient" :visitType="'second_visit'" :patient="patient" :user_id="user_id" @addVisit="addVisit" style="text-align: left;"/>
        </v-tab-item>

        <v-tab-item :key="'third_visit'">
            <AddVisit v-if="tab.selection==3 && patient" :visitType="'third_visit'" :patient="patient" :user_id="user_id" @addVisit="addVisit" style="text-align: left;"/>
        </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import CurrentPatients from './lib/current_patients.vue'
import AddVisit from './lib/add_visit.vue'

export default {
    components: {
        CurrentPatients,
        AddVisit
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            tab: {
                selection: 0
            },
            patient: null,
            // patients: []
        }
    },
    methods: {
        init: async function(){
            // await this.getPatients()
            // await this.getAssessments()
        },
        // getPatients: async function(){
        //     let user_id = this.user_id
        //     let response
        //     try {
        //         response = await this.sendRequest('get',`/api/patients/get_all/${user_id}`)
        //         let patients = response.data
        //         for(let i=0; i<patients.length; i++){
        //             patients[i].updated = new Date(patients[i].updated)
        //             if(patients[i].first_visit!=null){patients[i].first_visit = patients[i].first_visit.split('T')[0]}
        //             if(patients[i].second_visit!=null){patients[i].second_visit = patients[i].second_visit.split('T')[0]}
        //         }
        //         this.patients = patients
        //         if(this.patients.length==0){
        //             // this.$emit('noPatients')
        //             this.tab.selection=1
        //         }else{
        //             // this.$emit('setPatients', patients)
        //             this.patients = patients
        //         }
        //     } catch (error) {
        //         console.error('getPatients', error)
        //     }

        // },
        // getAssessments: async function(){
        //     let response = await this.sendRequest('get',`/api/program/assessments/${this.user_id}`)
        //     this.$emit('setAssessments',response.data)
        // },
        addVisit: async function(data){            
            // let response = await this.sendRequest('post',`/api/questionSet/saveAnswers/${data.question_set_id}/${data.version}`, {answers: data.patient_assessment, patient: data.patient})
            this.$emit('addVisit',data)
            this.tab.selection = 0
            this.patient = null
        },
        setPatients: async function(data){
            this.$emit('setPatients',data)
        },
        setAssessments: async function(data){
            this.$emit('setAssessments',data)
        },
        select_patient: async function(data){
            console.log('select_patient',data)
            this.patient = data
            this.tab.selection = data.first_visit===null ? 1 : data.second_visit===null ? 2 : 3
        }
    },
    computed: {
        user_id: function(){
            return this.$store.getters.user.attributes.sub
        }
    }
}
</script>

<style>

</style>