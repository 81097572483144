<template>
  <div class="overview">
    <v-container>
        <!-- <v-row> -->
            <v-col lg="6" md="12">
                <div style="text-align: left;">
                    <str :index="body"/>
                </div>
            </v-col>
            <v-col lg="6" md="12"></v-col>
        <!-- </v-row> -->
    </v-container>
  </div>
</template>

<script>
export default {
    props: {
        body: {
            type: String,
            required: true
        },
        background: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.overview{
    font-size: 1em;
}
</style>

<style>
.banner-body-header{
    font-size: 1.3em;
    font-weight: bold;
}
</style>