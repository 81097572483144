<template>
<div>
    <v-btn icon @click="display=!display">
        <v-icon>mdi-headset</v-icon>
    </v-btn>

    <v-dialog class="pa-0" v-model="display" width="1024" persistent>
        <v-card class="pa-0" v-if="display" style="background-position: bottom; background-size: stretch;" :style="{'background-image':'URL('+images.Background+')'}">
            <v-btn icon @click="display=false" style="position: absolute;right:0px;"><v-icon>mdi-close</v-icon></v-btn>
            <v-card-title>
                <str index="contact_us.header._text"/>
            </v-card-title>

            <v-card-subtitle>
                <str index="contact_us.subheader._text"/>
            </v-card-subtitle>

            <v-card-text>
                <v-form ref="contact_us" v-model="validForms.contact_us">
                    <v-select v-model="form.subject" :items="subjectItems" required :rules="rules.required" dense outlined>
                        <template v-slot:label>
                            <str index="forms.subject"/>
                        </template>
                    </v-select>

                    <v-text-field v-model="form.given_name" required :rules="rules.required" outlined>
                        <template v-slot:label>
                            <str index="forms.given_name"/>
                        </template>
                    </v-text-field>

                    <v-text-field v-model="form.family_name" required :rules="rules.required" outlined>
                        <template v-slot:label>
                            <str index="forms.family_name"/>
                        </template>
                    </v-text-field>

                    <v-text-field v-model="form.email" required :rules="rules.validEmail" :disabled="$store.getters.signedIn" outlined>
                        <template v-slot:label>
                            <str index="forms.email"/>
                        </template>
                    </v-text-field>
                    
                    <v-textarea
                        outlined
                        v-model="form.message"
                        required
                        :rules="rules.required"
                    >
                        <template slot="label">
                            <str index="forms.message._text"/>
                        </template>
                    </v-textarea>
                </v-form>
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                <v-btn class="themed" :disabled="busy" text @click="submit">
                    <str index="buttons.submit._text"/>
                </v-btn>
                <v-btn v-if="debug" color="error" text @click="ui.success.display=true">Success Message</v-btn>
                <v-spacer/>
            </v-card-actions>

        </v-card>
    </v-dialog>

    <v-dialog v-model="ui.success.display" width="500" style="overflow-y: visible !important;" persistent>
        <div style="position: relative;">

            <v-card>
                <div style="width: 100%; border: 1px solid green;background-color: green;height: 15px; margin-bottom: 35px;"/>

                <v-card-text style="text-align: center; font-size: 18pt; color: black;">
                    <div style="width: 90%; margin: 0 auto;">
                        <p>
                            <str index="contact_us.success.thanks"/>
                        </p>

                        <br>

                        <p style="font-weight: 300; line-height: 30px;">
                            <str index="contact_us.success.sent" style="color: green;"/>
                        </p>

                        <br>

                        <p style="font-weight: 300; line-height: 30px;">
                            <str index="contact_us.success.response_time"/>
                        </p>


                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="display=false; ui.success.display=false">
                        <v-icon left>mdi-close-circle</v-icon>
                        <str index="buttons.close"/>
                    </v-btn>
                </v-card-actions>
            </v-card>
            
            <div class="programDecal">
                <div class="programDecal-container">
                    <div class="programDecal-front-fold"/>
                    <div class="programDecal-back-fold"/>
                    <v-icon color="white" style="position: relative; left: 50%; top: 50%; transform: translate(-50%,-50%)">
                        mdi-check-bold
                    </v-icon>
                </div>
            </div>

            <div v-html="cssOverwrite"/>
            
        </div>

    </v-dialog>    

    <v-snackbar v-model="ui.snackbar.display">
        <str v-if="ui.snackbar.message" :index="ui.snackbar.message"/>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <str index="buttons.close"/>
        </v-btn>
      </template>
    </v-snackbar>
</div>
</template>

<script>
import Background from './assets/background.png'
import validations from '@/plugins/validations.js'

export default {
    created: function(){
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)        
        this.display = this.$attrs.value
    },
    data: function(){
        return {
            busy: false,
            ui: {
                success: {
                    display: false
                },
                snackbar: {
                    display: false,
                    message: null
                }
            },
            display: false,
            form: {
                subject: null,
                given_name: null,
                family_name: null,
                email: null,
                message: null
            },
            validations: null,
            rules: null,
            validForms: {
                contact_us: false
            }
        }
    },
    methods: {
        submit: async function(){
            let self = this
            self.busy = true
            self.$refs.contact_us.validate()
            if(this.validForms.contact_us){
                self.form.language = this.$store.getters.language
                let result = await self.sendRequest('post','/api/contactUs',self.form)
                if(result.status==200){
                    self.ui.success.display = true
                }else{
                    self.ui.snackbar.display = true
                    self.ui.snackbar.message = "Error sending message"
                }
            }else{
                self.ui.snackbar.display = true
                self.ui.snackbar.message = self.strMap.errors.incomplete
            }
            self.busy = false
        }
    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        images: function(){
            return {
                Background
            }
        },
        subjectItems: function(){
            let labels = this.labels
            let language = this.language
            
            let output = [
                {
                    text: labels?.contact_us?.subject_options?.about_technical_issue ? labels.contact_us.subject_options.about_technical_issue._text[language] : 'Missing: labels.contact_us.subject_options.about_technical_issue',
                    value: "about_technical_issue"
                },
                {
                    text: labels?.contact_us?.subject_options?.about_platform ? labels.contact_us.subject_options.about_platform._text[language] : 'Missing: labels.contact_us.subject_options.about_platform',
                    value: "about_platform"
                },
                {
                    text: labels?.contact_us?.subject_options?.other ? labels.contact_us.subject_options.other._text[language] : 'Missing: labels.contact_us.subject_options.other',
                    value: "other"
                }
            ]

            return output
        },
        language: function(){
            return this.$store.getters.language
        },
        labels: function(){
            return this.$store.getters.labels
        },
        strMap: function(){
            return {
                errors: {
                    incomplete: "contact_us.errors.incomplete"
                }
            }
        },
        cssOverwrite: function(){
            return `
            <style type='text/css'>
                .v-dialog.v-dialog--active{
                    overflow-y: visible !important;
                }
            </style>
            `
        }
    },
    watch: {
        display: function(){
            if(this.display && this.$store.getters.signedIn){
                let attributes = this.$store.getters.user.attributes
                this.form.given_name = attributes.given_name
                this.form.family_name = attributes.family_name
                this.form.email = attributes.email
            }else{
                for(let i in this.form){
                    this.form[i] = null
                }
            }
            this.$emit('input',this.display)
        }
    }
}
</script>

<style scoped lang="scss">
.programDecal{
    position: absolute;
    top: 0px;
    right: 25px;
}

.programDecal-container{
    position: relative;
    background-color: $primary;
    width: 50px;
    height: 60px;
}

.programDecal-back-fold{
    position: absolute;
    top: -8px;
    right: -6px;
    width: 10px;
    height: 8px;
    background-color: blue;
    z-index: 1;
}

.programDecal-front-fold{
    position: absolute;
    top: -8px;
    right: -3px;
    transform: skewX(-40deg);
    width: 100%;
    height: 8px;
    background-color: $primary;
    z-index: 2;
}
</style>