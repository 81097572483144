<template>
  <div class="step_header">
    <div class="step_header-item">
        <slot name="stepHeader"></slot>
    </div>
    <div class="square"/>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped lang="scss">
.step_header{
    display: flex;
    width: 100%;
    min-height: 100px;
    border: 1px solid teal;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    position: relative;
}

.step_header-item{
    color: white;
    font-weight: bold;
    font-size: 24pt;
    position: relative;
}

.square{
    position: absolute;
    bottom: -40px;
    width: 0; 
    height: 0; 
    border-left: 80px solid transparent;
    border-right: 80px solid transparent;  
    border-top: 40px solid $primary;
}
</style>