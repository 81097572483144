<template>
<div :class="[{'debug-mode':debug}]">
    <v-dialog v-model="ui.display">
        <v-card>
            <v-card-title></v-card-title>
            <v-card-text>
                <div class="prompt" >
                    <h5 v-if="debug" v-html="prompt.description"/>
                    <span v-html="prompt.content[language]"/>

                    <div class="footer" v-if="prompt.references.length>0">
                        <v-list style="background-color: transparent;">
                            <v-list-item v-for="referenceID in prompt.references" :key="'prompt_referernce_'+prompt.id+'_'+referenceID">
                                <v-list-item-content>
                                    <span v-html="referenceMap[referenceID].content[language]"/>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="close" class="themed">
                    <str index="buttons.close"/>
                </v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>

    </v-dialog>
    <!-- <json-viewer :value="prompt"/> -->
</div>
</template>

<script>
export default {
    name: "Prompt",
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        assets: {
            type: Array,
            required: false,
            default: function(){
                return []
            }
        },
        prompt: {
            type: Object,
            required: true
        },
        references: {
            type: Array,
            required: false,
            default: function(){
                return []
            }
        }
    },
    data: function(){
        return {
            ui: {
                display: false
            },
            timer: null
        }
    },
    methods: {
        close: function(){
            this.ui.display = false
            this.$emit('close_prompt', this.prompt)
        }
    },
    computed: {
        language: function(){
            return this.$store.getters.language
        },
        referenceMap: function(){
            let references = this.references
            let output = {}
            for(let i=0; i<references.length; i++){
                let reference = references[i]
                output[reference.id] = reference
            }
            return output
        },
        assetMap: function(){
            let assets = this.assets
            let output = {}
            for(let i=0; i<assets.length; i++){
                let asset = assets[i]
                output[asset.id] = asset
            }
            return output
        }
    },
    watch: {
        prompt: {
            deep: true,
            handler: function(){
                let self = this
                clearTimeout(self.timer)
                self.timer = setTimeout(function(){
                    if(self.prompt.display){
                        self.ui.display = true
                    }
                },800)
            }
        }
    }
}
</script>

<style scoped>
.prompt{
    width: 100%;
    color: black;
    font-size: 1.5em;
    /* min-height: 150px; */
}

.footer{
    background-color: transparent;
    margin: 25px;
    border-top: 1px solid whitesmoke;
    font-size: 0.7em;
}
</style>

<style>
.debug-mode [logic]{
    border: 1px solid purple;
    margin: 5px;
}

img[asset]{
    max-width: 100%;
}
</style>