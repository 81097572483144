<template>
  <v-app class="app">
    <div @click="$store.commit('inactivity',0)" v-if="$store.getters.siteBlocker===false">
      <Header/>
      <v-main v-if="ready" class="app-main">
        <Banner v-if="['Home'].includes($route.name) && $store.getters.signedIn"/>
        <router-view/> 
      </v-main>
      <Footer/>
    </div>
    <siteBlocker/>
  </v-app>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Header from '@/components/Header/Header'
import Banner from '@/components/Banner/Banner'
import Footer from '@/components/Footer/Footer'
import siteBlocker from '@/components/siteBlocker/siteBlocker'

export default {
  name: 'App',
  components: {
    Header,
    Banner,
    Footer,
    siteBlocker
  },
  created: async function(){
    let self = this
    Vue.prototype.sendRequest = self.sendRequest

      var hidden, visibilityChange;
      if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
        hidden = "hidden";
        visibilityChange = "visibilitychange";
      } else if (typeof document.msHidden !== "undefined") {
        hidden = "msHidden";
        visibilityChange = "msvisibilitychange";
      } else if (typeof document.webkitHidden !== "undefined") {
        hidden = "webkitHidden";
        visibilityChange = "webkitvisibilitychange";
      }
      document.addEventListener(visibilityChange, function (event) {
          if (document[hidden]) {
              self.$store.commit('appVisible',false)
          } else {
              self.$store.commit('appVisible',true)
          }
      });

      window.addEventListener('resize', this.handleResize);

      await this.initLabels()
      this.handleResize()
      this.ready = true

  },
  destroyed() {
      window.removeEventListener('resize', this.handleResize);
  },
  data: function(){
    return {
      ready: false,
      authenticatorReady: false,
      ui: {
        contact_us: false,
        exposeLabels: false
      },
      session: {
        expires: null,
        time_left: null,
        timer: null,
        inactivity_timer: null
      }
    }
  },
  methods: {
    sendRequest: function(method='get', url, data=null){
      let self = this
      return new Promise((resolve, reject)=>{
        if(self.$store.getters.user){
          axios.defaults.headers['Authorization'] = `Bearer ${self.$store.getters.user.signInUserSession.idToken.jwtToken}`
        }else{
          delete axios.defaults.headers['Authorization']
        }
        if(process.env.NODE_ENV!='development'){
          url = url.replace('/api',process.env.VUE_APP_API)
        }
        axios({
          method,
          url,
          data
        }).then((response)=>{
          if(response.headers['content-type']=="text/calendar; charset=utf-8"){
            let filename = "meeting.ics"
            if(filename){
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', filename);
              link.setAttribute('id','download-link')
              document.body.appendChild(link);
              link.click();
              link.remove();
            }

          }
          resolve(response)
        },(error)=>{
          reject(error)
        })
      });
    },
    initLabels: async function(){
      let labels = await this.sendRequest('get','/api/label/downloadAll')
      this.$store.commit('labels',labels.data)
    },
    handleResize: function(){
      this.$store.commit('width', window.innerWidth)
      this.$store.commit('height', window.innerHeight)
    }
  },
  computed: {
    language: function(){
      return this.$store.getters.language
    },
    images: function(){
      return {
        logo: Logo
      }
    },
    signedIn: function(){
      return this.$store.getters.signedIn
    },
    debug: function(){
      return this.$store.getters.debug
    }
  }
};
</script>


<style lang="scss">
.app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1600px;
  margin: 0 auto;
}
body{
  background-color: whitesmoke;
}
.v-application--wrap{
  min-height: 100px !important;
}

.v-main .v-btn.themed, .v-dialog .v-btn.themed{
  background-color: $primary !important;
  color: white !important;
}
</style>
