import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    siteBlocker: false,
    debug: false,
    language: null,
    languages: null,
    labels: undefined,
    exposeLabels: false,
    width: null,
    height: null,
    pathAfterLogin: null,
    inactivity: 0,
    timezone: null,
    user: undefined,
    userID: 'guest',
    signedIn: false,
    appVisible: true
  },
  getters: {
    pathAfterLogin: function(state){
      return state.pathAfterLogin
    },
    debug: function(state){
      return state.debug
    },
    language: function(state){
      return state.language
    },
    languages: function(state){
      return state.languages
    },
    labels: function(state){
      return state.labels
    },
    width: function(state){
      return state.width
    },
    height: function(state){
      return state.height
    },
    inactivity: function(state){
      return state.inactivity
    },
    siteBlocker: function(state){
      return state.siteBlocker
    },
    timezone: function(state){
      return state.timezone
    },
    exposeLabels: function(state){
      return state.exposeLabels
    },
    user: function(state){
      return state.user
    },
    userID: function(state){
      return state.userID
    },
    signedIn: function(state){
      return state.signedIn
    },
    appVisible: function(state){
      return state.appVisible
    }
  },
  actions: {
    pathAfterLogin: function(context, payload){
      context.commit('pathAfterLogin',payload)
    },
    debug: function(context, payload){
      context.commit('debug',payload)
    },
    language: function(context, payload){
      context.commit('language',payload)
    },
    languages: function(context, payload){
      context.commit('languages',payload)
    },
    labels: function(context, payload){
      context.commit('labels',payload)
    },
    width: function(context, payload){
      context.commit('width',payload)
    },
    height: function(context, payload){
      context.commit('height',payload)
    },
    inactivity: function(context, payload){
      context.commit('inactivity',payload)
    },
    siteBlocker: function(context, payload){
      context.commit('siteBlocker',payload)
    },
    timezone: function(context, payload){
      context.commit('timezone',payload)
    },
    exposeLabels: function(context, payload){
      context.commit('exposeLabels',payload)
    },
    user: function(context, payload){
      context.commit('user',payload)
    },
    userID: function(context, payload){
      context.commit('userID',payload)
    },
    signedIn: function(context, payload){
      context.commit('signedIn',payload)
    },
    appVisible: function(context, payload){
      context.commit('appVisible',payload)
    }
  },
  mutations: {
    pathAfterLogin: function(state, payload){
      state.pathAfterLogin = payload
    },
    debug: function(state, payload){
      state.debug = payload
    },
    language: function(state, payload){
      state.language = payload
    },
    languages: function(state, payload){
      state.languages = payload
    },
    labels: function(state, payload){
      state.labels = payload
    },
    width: function(state, payload){
      state.width = payload
    },
    height: function(state, payload){
      state.height = payload
    },
    inactivity: function(state, payload){
      state.inactivity = payload
    },
    siteBlocker: function(state, payload){
      state.siteBlocker = payload
    },
    timezone: function(state, payload){
      state.timezone = payload
    },
    exposeLabels: function(state, payload){
      state.exposeLabels = payload
    },
    user: function(state, payload){
      state.user = payload;
      if(payload===undefined){
        state.signedIn = false
        state.userID = 'guest'
        state.exposeLabels = false
        state.debug = false
      }else{
        if(state.languages.includes(state.user.attributes['custom:language'])){
          state.language = state.user.attributes['custom:language']
        }
        state.signedIn = true
        state.userID = state.user.signInUserSession.accessToken.payload.sub ? state.user.signInUserSession.accessToken.payload.sub : 'guest'
      }
    },
    userID: function(state, payload){
      state.userID = payload
    },
    signedIn: function(state, payload){
      state.signedIn = payload
    },
    appVisible: function(state, payload){
      state.appVisible = payload
    }
  },
  modules: {
  }
})
