<template>
    <v-footer class="footer" :class="[{'login':$route.name=='SignIn'}]">
        <v-container style="max-width: none;">
            <v-row :no-gutters="true" align-content="center" align="center" justify="center">
                <v-col :cols="12" :md="4"/>
                <v-col :cols="12" :md="4" align-self="center" style="text-align: center; color: white;">
                  <str index="footer.all_rights"/>
                </v-col>
                <v-col :cols="12" :md="4" align-self="center" class="right-col">
                  <div>
                    <v-btn style="padding: 5px;" text dark link @click="popup=true; content='terms'"><str index="footer.terms_of_use.link"/></v-btn>
                    | 
                    <v-btn style="padding: 5px;" text dark link @click="popup=true; content='privacy'"><str index="footer.privacy_policy.link"/></v-btn>
                  </div>
                </v-col>
            </v-row>          
        </v-container>

        <v-dialog v-model="popup">
            <v-card>
                <v-card-title>
                    <str v-if="content=='terms'" index="footer.terms_of_use.title"/>
                    <str v-if="content=='privacy'" index="footer.privacy_policy.title"/>
                </v-card-title>
                <v-card-text style="text-align:left;">
                    <str v-if="content=='terms'" index="footer.terms_of_use.content"/>
                    <str v-if="content=='privacy'" index="footer.privacy_policy.content"/>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn class="themed" @click="close">
                        <str index="buttons.close"/>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-footer>
</template>

<script>
export default {
    data: function(){
        return {
            popup: false,
            content: null
        }
    },
    methods: {
        close: function(){
            document.getElementsByClassName('v-dialog--active')[0].scrollTo(0,0)
            this.popup=false            
        }
    },
    computed: {
        show: function(){
            let groups = this.$store.getters.user && this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] ? this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] : []
            let allow = ['SuperAdmin','staff','developer','tester']
            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(allow.includes(group)){
                    return true
                }
            }
            return false
        }
    }
}
</script>

<style scoped lang="scss">
.footer{
    background-color: $primary !important;
    color: white !important;
}

.footer.login, .footer.login .v-btn{
    // color: darkgrey;
}

.right-col{
    text-align: right;
}

@media only screen and (max-width: 800px){

    .right-col{
        text-align: center;
    }
    
}
</style>