<template>
<div class='header'>
    <v-app-bar flat color="white">
      <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->

    <v-toolbar-title v-if="$store.getters.signedIn" @click="$router.push({name:'Home'})">
        <img style="cursor: pointer;" :src="images.logo" height="60"/>
    </v-toolbar-title>

    <div class='backToHome' v-if="$store.getters.signedIn && $route.name!='Home'" @click="$router.push({name:'Home'})">
        <img :src="images.icon_back" alt="Back arrow icon">
        <p><str index="buttons.back_to_home"/></p>
    </div>

      <v-spacer></v-spacer>

        <div class="app-bar-item">
          <ContactUs/>
        </div>


        <div class="app-bar-item" style="color: white;">
          <Authenticator v-if="$route.name!='SignIn'" @authenticatorReady="authenticatorReady=true" :buttons="{signOut:true}" :badge="true" style="margin: 5px;"/>
        </div>
        <div style="width: 120px;position: relative; top: 12px;">
            <Language/>
        </div>
    </v-app-bar>
</div>
</template>

<script>
import Authenticator from '@/components/Authenticator/Authenticator'
import ContactUs from './ContactUs/ContactUs.vue'
import logo_en from '@/assets/logo.png'
import logo_fr from '@/assets/logo_fr.png'
import icon_back from './assets/circle-left-solid.png'
export default {
  components: {
    Authenticator,
    ContactUs
  },
  computed: {
    language: function(){
      return this.$store.getters.language
    },
    images: function(){
      return {
        logo: this.language=='fr-ca' ? logo_fr : logo_en,
        icon_back: icon_back
      }
    }
  }
}
</script>

<style lang='scss'>
    .header {
        height: 90px;
        position: relative;
    }
    .v-toolbar__title {
        cursor: pointer;
        @media(max-width: 448px){
            width: 46px;
        }
    }
    .backToHome {
        cursor: pointer;
        display: flex;
        position: absolute;
        top: 64px;
        left: 32px;
        @media(max-width: 575px){
            left: 28px;
        }

        img {
            width: 16px;
            height: 16px;
            margin-top: 3px;
            margin-right: 8px;
        }

        p{
            font-size: 15px;
        }
    }
</style>
