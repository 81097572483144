<template>
<v-container style="padding: 0px;">
    <!-- <v-row> -->
        <v-col lg="6" md="0"/>
        <v-col lg="6" md="12">
            <v-card>
                <v-card-subtitle>
                    <str index="banner.tabs.resources.table_title"/>
                </v-card-subtitle>
                <v-card-text>
                    <v-data-table hide-default-footer :headers="headers" :items="items"><!--hide-default-header -->
                        <!-- <template v-slot:header="{ props }">
                            <thead class="v-data-table-header">
                                <tr>
                                    <th v-for="head in props.headers"
                                        :key="head.value" class="text-start sortable">
                                        <str :index="head.label"/>
                                    </th>
                                </tr>
                            </thead>
                        </template> -->


                        <template v-slot:item.link="{item}">
                            <a :href="item.link" target="_blank">
                                <str index="banner.tabs.resources.table_col_download"/>
                            </a>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-col>
    <!-- </v-row> -->
</v-container>
</template>

<script>
export default {
    computed: {
        headers: function(){
            return [
                {
                    // text: 'File Name',
                    // label: 'banner.tabs.resources.table_col_filename',
                    text: this.$store.getters.labels.banner.tabs.resources.table_col_filename._text[this.language],
                    value: 'filename'
                },
                {
                    // text: 'Publishing Date',
                    // label: 'banner.tabs.resources.table_col_publishingdate',
                    text: this.$store.getters.labels.banner.tabs.resources.table_col_publishingdate._text[this.language],
                    value: 'date'
                },
                {
                    // text: 'Language',
                    // label: 'banner.tabs.resources.table_col_language',
                    text: this.$store.getters.labels.banner.tabs.resources.table_col_language._text[this.language],
                    value: 'language'
                },
                {
                    // text: 'Download',
                    // label: 'banner.tabs.resources.table_col_download',
                    text: this.$store.getters.labels.banner.tabs.resources.table_col_download._text[this.language],
                    value: 'link'
                }
            ]
        },
        language: function(){
            return this.$store.getters.language
        },
        items: function(){
            return [
                {
                    filename: 'IRB Protocol',
                    date: 'Nov 2023',
                    language: 'English',
                    link: '/assets/protocol.pdf'
                },
                {
                    filename: 'Treat to Target Publication',
                    date: 'May 2022',
                    language: 'English',
                    link: '/assets/Treat to Target Publication.pdf'
                }
            ]
        }
    }
}
</script>

<style>

</style>