import Vue from 'vue'
import VueRouter from 'vue-router'
import { Auth } from 'aws-amplify';
let cache = require('@/plugins/cache.js')
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue'),
    meta: {
      public: false,
      allowed: '*'
    }
  },
  {
    path: '/restricted',
    name: 'Restricted',
    component: () => import('../views/Restricted.vue'),
    meta: {
      public: true
    }
  },
  {
    path: '/login',
    meta: {
      public: true
    },
    component: () => import('../views/Login.vue'),
    children: [
      {
        name: 'SignIn',
        path: '/login',
        meta: {
          public: true
        }
      }
    ]
  },
  {
    path: '/participant',
    meta: {
      public: true
    },
    component: () => import('../views/Login.vue'),
    children: [
      {
        path: '/participant/',
        meta: {
          public: true
        },
        children: [
          {
            name: 'Participant',
            path: '/participant/:hash',
            meta: {
              public: true
            }
          }
        ]
      }
    ]
  },
  {
    path: '/',
    name: 'Home',
    meta: {
      public: false,
      allowed: '*'
    },
    component: Home
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Admin.vue'),
    meta: {
      public: false,
      allowed: ['developer','SuperAdmin']
    },
  },
  {
      path: '/DataDump',
      name: 'DataDump',
      component: () => import('../views/DataDump.vue'),
      meta: {
        public: false,
        allowed: ['developer','SuperAdmin', 'staff', 'tester']
    }
  },
  {
      path: '/dashboard',
      name: 'Dashboard',
      component: () => import('../views/Dashboard.vue'),
      meta: {
        public: false,
        allowed: ['developer','SuperAdmin', 'staff', 'client']
    }
  },
  {
    path: '/labelAdmin',
    component: () => import('../views/LabelAdmin.vue'),
    meta: {
      public: false,
      allowed: ['staff','developer','SuperAdmin','tester']
    },
    children: [
      {
        path: '/labelAdmin/edit/:index',
        name: 'editLabel',
        meta: {
          public: false,
          allowed: ['staff','developer','SuperAdmin','tester']
        }
      },
      {
        path: '/labelAdmin/new',
        name: 'newLabel',
        meta: {
          public: false,
          allowed: ['staff','developer','SuperAdmin','tester']
        }
      }
    ]
  },
  {
    path: '/questionSetEditor',
    name: 'questionSetEditor',
    component: () => import('../views/QuestionSetEditor.vue'),
    meta: {
      public: false,
      allowed: ['developer','SuperAdmin']
    },
    children: [
      {
        path: '/questionSetEditor/:questionSet',
        meta: {
          public: false,
          allowed: ['developer','SuperAdmin']
        }
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next)=>{
  if(to.meta.public){
    next()
  }else{
    let findUser = async function(){
      try{
        const user = await Auth.currentAuthenticatedUser()
        let groups = user.signInUserSession.accessToken.payload['cognito:groups']
        let allow = to.meta.allowed.includes('*')

        if(typeof groups=='object' && groups.length!=undefined){
          for(let i=0; i<groups.length; i++){
            let group = groups[i]
            if( to.meta.allowed.includes(group) ){
              allow = true
              break;
            }
          }
        }

        if(allow){
          next()
        }else{
          next('/restricted')
        }
      }catch(err){
        cache.storage.local.set('pathAfterLogin',to.fullPath)
        next('/login')
      }
    }

    findUser()
  }

})

export default router
