<template>
  <div>
    <!-- <v-stepper class="steps" v-model="step.selection">
        <v-stepper-header>
            <template v-for="(stepItem, stepIndex) in step.list" >
                <v-stepper-step editable :key="'step_header_'+stepIndex" :step="stepIndex" @click="step.selection=stepIndex">
                    {{stepItem.label}}
                </v-stepper-step>
                <v-divider v-if="stepIndex<step.list.length-1" :key="'step_header_divider_'+stepIndex"/>
            </template>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content v-for="(step, stepIndex) in step.list" :key="'step_'+stepIndex" :step="stepIndex">
                {{step.label}}
            </v-stepper-content>
        </v-stepper-items>
    </v-stepper> -->

    <v-card :style="$store.getters.width<=800 ? {'padding':'0px'} : {}">
        <v-card-subtitle>
            <div style="width: 80%; margin: 0 auto;">
                <v-progress-linear
                :value="_progress"
                height="25"
                @click="ui.progress_details = !ui.progress_details"
                >
                    <template v-slot:default="{ value }">
                        <strong>{{ value<=100 ? Math.ceil(value) : 100 }}%</strong>
                    </template>
                </v-progress-linear>
            </div>
            <div v-show="ui.progress_details && $store.getters.debug" style="border: 1px solid lightgrey; border-radius: 8px; padding: 25px;">
                PATIENTS: {{patients.length}} of {{requirements.patients}}
                <br>
                First Visits: {{visits.first}} of {{requirements.first_visits}}
                <br>
                Second Visits: {{visits.second}} of {{requirements.second_visits}}
                <br>
                Third Visits: {{visits.third}} of {{requirements.third_visits}}
                <br>
                Total Assessments: {{(visits.first+visits.second+visits.third)}} of {{requirements.first_visits+requirements.second_visits+requirements.third_visits}}
            </div>

        </v-card-subtitle>
        <v-card-text :style="$store.getters.width<=800 ? {'padding':'0px'} : {}">
            <PatientManager v-if="!reloadPatientManager" @setPatients="setPatients" @setAssessments="setAssessments" @addVisit="reloadPatientManager=true"/>
            <!-- <component v-bind:is="step.list[step.selection].component" @setPatients="setPatients" @setAssessments="setAssessments" @addVisit="$forceUpdate()"/> -->
        </v-card-text>
    </v-card>
    
  </div>
</template>

<script>
import PracticeProfile from '@/components/ProgramFlow/practice_profile'
import PatientManager from '@/components/ProgramFlow/patient_manager'

export default {
    components: {
        PracticeProfile,
        PatientManager
    },
    data: function(){
        return {
            ui: {
                progress_details: false
            },
            reloadPatientManager: false,
            config: {
                requirements: {
                    patients: 10
                }
            },
            step: {
                selection: 0,
                list: [
                    // {
                    //     label: 'program.steps.practice_profile.title',
                    //     component: 'PracticeProfile'
                    // },
                    {
                        label: 'program.steps.patient_assessment.title',
                        component: 'PatientManager'
                    }
                ]
            },
            patients: [],
            assessments: []
        }
    },
    methods: {
        setPatients: function(data){
            console.log('steps > setPatients', data)
            this.patients = data
        },
        setAssessments: function(data){
            console.log('steps > setAssessments', data)
            this.assessments = data
        }
    },
    computed: {
        visits: function(){
            let output = {
                first: 0,
                second: 0,
                third: 0
            }
            let assessments = this.assessments
            for(let i=0; i<assessments.length; i++){
                let assessment = assessments[i]
                if(assessment.visit_type=='first_visit'){
                    output.first++
                }
                if(assessment.visit_type=='second_visit'){
                    output.second++
                }
                if(assessment.visit_type=='third_visit'){
                    output.third++
                }
            }

            return output
        },
        requirements: function(){
            let requirements = this.config.requirements
            return {
                patients: requirements.patients,
                first_visits: requirements.patients*1,
                second_visits: requirements.patients*1,
                third_visits: requirements.patients*1,
                total_visits: requirements.patients*3
            }
        },
        _progress(){
            let requirements = this.requirements
            let patients = this.patients
            let visits = this.visits

            let completed_patients = (patients.length / requirements.patients) * 100
            let completed_first = (visits.first / requirements.first_visits) * 100
            let completed_second = (visits.second / requirements.second_visits) * 100
            let completed_third = (visits.third / requirements.third_visits) * 100

            return (completed_patients + completed_first + completed_second + completed_third) / 4
            // ((visits.first+visits.second+visits.third)/(requirements.first_visits+requirements.second_visits+requirements.third_visits))*100
        }
    },
    watch: {
        reloadPatientManager: function(){
            let self = this
            setTimeout(function(){
                self.reloadPatientManager = false
            },1000)
        }
    }
}
</script>

<style lang="scss">
.steps{
  width: 800px;
  margin: 0 auto;
}
</style>