<template>
  <div>
    <Survey v-if="ready" v-model="questionSet" :questionSetID="questionSetID" :requestDebug="debug" :busy="busy" @submit="submit" @close_prompt="closePrompt"/>
  </div>
</template>

<script>
import Survey from '@/components/Questions/QuestionSet'

export default {
    props: {
        visitType: {
            type: String,
            required: true
        },
        busy: {
            type: Boolean,
            required: true
        }
    },
    components:{
        Survey
    },
    created: function(){
        if(this.visitType=='first_visit'){
            this.questionSetID = 1
            this.questionSetVersion = 3
        }else if(this.visitType=='second_visit'){
            this.questionSetID = 2
            this.questionSetVersion = 3
        }else if(this.visitType=='third_visit'){
            this.questionSetID = 3
            this.questionSetVersion = 1
        }else{
            console.error('Missing Visit Type')
        }

        if(this.questionSetID && this.questionSetVersion != null){
            this.init()
        }
    },
    data: function(){
        return {
            questionSetID: null,
            questionSetVersion: null,
            ready: false,
            questionSet: null
        }
    },
    methods: {
        init: async function(){
            await this.getQuestionSet()
            this.ready = true
        },
        getQuestionSet: async function(){
            let self = this
            let getData = async function(){
                let response
                try{
                    response = await self.sendRequest('get',`/api/questionSet/getQuestions/${self.questionSetID}`)
                }catch(err){
                    console.error('getQuestionSet error', err)
                }
                return response.status==200 ? response.data : response
            }

            self.questionSet = await getData()            
        },
        submit: async function(data){
            let emitPackage = {patient_assessment: data, question_set_id: this.questionSetID, version: this.questionSetVersion}
            console.log('patient_assessment > submit', emitPackage)
            this.$emit('submit',emitPackage)
        },
        closePrompt: function(prompt){
            if(this.visitType=='first_visit'){
                if(prompt.id==1){
                    this.questionMap[15].form.group[1].value=null              
                }
                if(prompt.id==2){
                    this.questionMap[1].form.group[1].value[1]=null              
                }
                if(prompt.id==3){
                    let qids = [9,14]
                    for(let q=0; q<qids.length; q++){
                        let qid = qids[q]
                        for(let i in this.questionMap[qid].form.group[1].value){
                            let value = parseInt(this.questionMap[qid].form.group[1].value[i],10)
                            let shouldReset = !isNaN(value) && (value<0 || value>100)
                            if(shouldReset){
                                this.questionMap[qid].form.group[1].value[i] = null
                            }
                        }
                    }
                }
                if(prompt.id==4){
                    let qids = [4]
                    for(let q=0; q<qids.length; q++){
                        let qid = qids[q]
                        for(let i in this.questionMap[qid].form.group[1].value){
                            let value = parseInt(this.questionMap[qid].form.group[1].value[i],10)
                            let shouldReset = !isNaN(value) && (value<0 || value>100)
                            if(shouldReset){
                                this.questionMap[qid].form.group[1].value[i] = null
                                let elem = document.querySelector("[data-question-id='"+qid+"']")
                                elem.scrollIntoView({behavior:'smooth'})
                            }
                        }
                       
                    }
                }

            }
            if(this.visitType=='second_visit' || this.visitType=='third_visit'){
                if(prompt.id==1){
                    let qids = [1]
                    for(let q=0; q<qids.length; q++){
                        let qid = qids[q]
                        for(let i in this.questionMap[qid].form.group[1].value){
                            let value = parseInt(this.questionMap[qid].form.group[1].value[i],10)
                            let shouldReset = !isNaN(value) && (value<0 || value>100)
                            if(shouldReset){
                                this.questionMap[qid].form.group[1].value[i] = null
                            }
                        }
                    }      
                }
                if(prompt.id==2){
                    this.questionMap[4].form.group[1].value = []
                }
                if(this.visitType=='third_visit' && prompt.id==6){
                    let targets = [19,20,21,22,23]
                    function validate(question){
                        let id = question.id
                        let display = question.display
                        let value = parseInt(question.form.group[1].value[1], 10)

                        if(display){
                            let valid = (value>=0 && value<=5)
                            if(!valid){
                                question.form.group[1].value[1] = null
                            }

                        }
                    }
                    for(let i=0; i<targets.length; i++){
                        let qid = targets[i]
                        validate(this.questionMap[qid])
                    }
                }

            }
            console.log('closePromopt',{prompt})
        }
    },
    computed: {
        questionMap: function(){
            let output = {}
            let questionSet = this.questionSet

            if(questionSet && questionSet.questions){
                let questions = this.questionSet.questions
                for(let i=0; i<questions.length; i++){
                    let question = questions[i]
                    output[question.id] = question
                }
            }

            return output
        },
        logicMap: function(){
            let output = {}
            let questionSet = this.questionSet

            if(questionSet && questionSet.logics){
                let logics = questionSet.logics
                for(let i=0; i<logics.length; i++){
                    let logic = logics[i]
                    output[logic.id] = logic
                }
            }

            return output
        },
        customValidation: function(){
            let questionMap = this.questionMap
            let visitType = this.visitType
            let output = {}
            if(visitType=='third_visit'){
                let targets = [1,19,20,21,22,23]
                for(let i=0; i<targets.length; i++){
                    let qid = targets[i]
                    output[qid] = questionMap[qid]
                }
                return output
            }
            return null
        },
        debug: function(){
            return this.$store.getters.debug
        }
    },
    watch: {
        customValidation: {
            deep: true,
            handler: function(){
                let questions = this.customValidation
                if(questions){
                    for(let qid in questions){
                        let question = questions[qid]
                        if(question.form.group){
                            for(let v in question.form.group[1].value){
                                
                                if(question.display && question.form.group[1].value[v]!=null){
    
                                    if(qid!=1 || qid==1 && [3,4].includes(parseInt(v,10))){
                                        let number = parseFloat(question.form.group[1].value[v],10)
                                        let string = question.form.group[1].value[v]
                                        let hasDecimal = string.indexOf('.')>=0
                                        let isInteger = Number.isInteger(number)
                                                                   
                                        if(!isInteger || hasDecimal){
                                            question.form.group[1].value[v] = null
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</script>

<style>

</style>