<template>
<div>    
    <v-list>
        <v-list-item v-show="group.text[language] && group.text[language].length>0">
            <v-list-item-content>
                <!-- <v-list-item-title> -->
                    {{group.text[language]}}
                <!-- </v-list-item-title> -->
            </v-list-item-content>
        </v-list-item>
        
        <v-list-item v-for="choice in group.choices" :key="'qid_'+question.id+'_gid_'+group.id+'_cid_'+choice.id">
            <!-- <v-list-item-content> -->
                <v-list-item-content style="padding: 0px 0px 0px 5px;">
                    <v-checkbox dense v-model="form.value" v-show="choice.display" :disabled="question.display===false || choice.disabled===true" :value="choice.value ? choice.value : choice.id" @change="checkboxHandler(group, choice)">
                        <template v-slot:label>
                            <span v-html="choice.text[language]"/>
                        </template>
                    </v-checkbox>
                    <!-- <v-text-field v-if="form.value.includes(choice.value) && choice.other" v-model="form.others[choice.id]" @blur="trim(group.id, choice.id)" :label="choice.other_text[language]"/> -->
                    <other v-if="form.value.includes(choice.value) && choice.other" v-model="form.others[choice.id]" :label="choice.other_text[language]"/>
                </v-list-item-content>
            <!-- </v-list-item-content> -->
        </v-list-item>        
    </v-list>
</div>
</template>

<script>
import other from './other'
export default {
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        question: {
            type: Object,
            required: true
        },
        group: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    components: {
        other
    },
    created: function(){
        this.form = this.$attrs.value
    },
    data: function(){
        return {
            form: {}
        }
    },
    methods: {
        checkboxHandler: function(group, choice){

            if(this.form.value!=null && this.form.value.includes(choice.value)){
                if(choice.exclusive){
                    this.form.value = [choice.value]
                }else{
                    let exclusives = []
                    let filtered = []
                    for(let c=0; c<group.choices.length; c++){
                        let choice = group.choices[c]
                        if(choice.exclusive){
                            exclusives.push(choice.value)
                        }
                    }
                    for(let v=0; v<this.form.value.length; v++){
                        let value = this.form.value[v]
                        if(!exclusives.includes(value)){
                            filtered.push(value)
                        }
                    }
                    this.form.value = filtered
                }
            }
        }
    },
    computed: {
        
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.$emit('input',this.form)
            }
        }
    }
}
</script>

<style>

</style>