<template>
<div>

    <v-card elevation='0'>
        <v-card-text>
            <v-form ref="moreInfo" v-model="validForms.moreInfo">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-icon large>
                                mdi-account-cog
                            </v-icon> 
                            <str index="authenticator.edit_profile.info_account"/>
                        </v-col>

                        <v-col>
                            <v-icon large>
                                mdi-account-circle-outline
                            </v-icon> 
                            <str index="authenticator.edit_profile.info_additional"/>
                        </v-col>
                    </v-row>
                    <v-row>
                        
                        <v-col style="text-align: center;">
                            <v-text-field outlined dense v-model="model.given_name" required :rules="rules.required">
                                <template v-slot:label>
                                    <str index="forms.given_name"/>
                                </template>                        
                            </v-text-field>
                            <v-text-field outlined dense v-model="model.family_name" required :rules="rules.required">
                                <template v-slot:label>
                                    <str index="forms.family_name"/>
                                </template>                        
                            </v-text-field>

                            <div style="text-align: left;">
                                <v-select @change="$store.commit('language',temp.language)" outlined dense v-model="temp.language" :items="[{text: 'English', value: 'en-ca'}, {text: 'Français', value: 'fr-ca'}]">
                                    <template v-slot:label>
                                        <str index="authenticator.edit_profile.preferred_language"/>
                                    </template>
                                </v-select>
                            </div>

                            <v-text-field outlined dense v-model="email.new" required :rules="rules.validEmail">
                                <template v-slot:label>
                                    <str index="forms.email"/>
                                </template>   
                                <template v-slot:append-outer>
                                    <v-btn :disabled="email.new==$store.getters.user.attributes.email || busy" text @click="changeEmail">
                                        <str index="authenticator.buttons.change_email"/>
                                    </v-btn>
                                </template>
                            </v-text-field>
                            <v-btn  class="themed" @click="ui.changePassword = true">
                                <str index="authenticator.buttons.change_password"/>
                            </v-btn>
                        </v-col>

                        <v-col>
                            <template v-for="(field, field_index) in fields">

                                <v-text-field v-if="field.type=='number'" :key="`field_${field_index}`" outlined v-model="model[field.target]" :onkeydown="'javascript: return event.keyCode === 8 || event.keyCode === 46 ? true : !isNaN(Number(event.key))'"
                                    dense
                                    type="number"
                                    :required="field.required"
                                    :rules="field.rules"
                                >
                                    <template v-slot:label>
                                        <str :index="field.label"/>
                                    </template>
                                </v-text-field>

                                <v-text-field v-if="field.type=='textfield'" :key="`field_${field_index}`" outlined v-model="model[field.target]" 
                                    dense
                                    :required="field.required"
                                    :rules="field.rules"
                                >
                                    <template v-slot:label>
                                        <str :index="field.label"/>
                                    </template>
                                </v-text-field>

                                <v-autocomplete v-if="field.type=='autocomplete'" :key="`field_${field_index}`" v-model="model[field.target]" 
                                    :items="field.items" 
                                    :required="field.required"
                                    :rules="field.rules"
                                    background-color="white"
                                    autocomplete="disable_autocomplete"
                                    dense
                                    outlined
                                >
                                    <template v-slot:label>
                                        <str :index="field.label" />
                                    </template>                    
                                </v-autocomplete>

                                <v-select v-if="field.type=='select'" :key="`field_${field_index}`" v-model="model[field.target]"
                                    :items="field.items" 
                                    :required="field.required"
                                    :rules="field.rules"
                                    background-color="white"
                                    dense
                                    outlined
                                >
                                    <template v-slot:label>
                                        <str :index="field.label" />
                                    </template>    

                                </v-select>

                                <v-checkbox v-if="field.type=='casl'" :true-value="true" :false-value="false" :key="`field_${field_index}`" v-model="model[field.target]"
                                    :items="field.items" 
                                    background-color="white"
                                    dense
                                    outlined
                                    style="position: relative; top: -20px;"
                                >
                                    <template v-slot:label>
                                        <str :index="field.label" style="position: relative; top: 20px;"/>
                                    </template>    

                                </v-checkbox>

                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn color="primary" class="themed" :rounded="false" :disabled="!validForms.moreInfo" @click="$emit('updateProfile',model)"><str index="buttons.save"/></v-btn>
            <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>

    <v-dialog v-model="ui.changeEmail" persistent width="400" :hide-overlay="true" :retain-focus="true">
        <v-card v-if="ui.changeEmail">
            <v-card-title>
                <str index="authenticator.edit_profile.change_email.title"/>
            </v-card-title>
            <v-card-text>
                    <str index="authenticator.edit_profile.change_email.text"/>: {{email.new}}
                    <v-otp-input
                        v-model="email.pin"
                        type="number"
                        :length="config.pinLength"
                    />            
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                    <v-btn class="themed" color="primary" :disabled="email.pin==null || email.pin.length<config.pinLength" @click="verifyEmailChange">
                        <str index="authenticator.buttons.verify"/>
                    </v-btn>
                <v-spacer/>
            </v-card-actions> 

            <v-card-actions>
                <v-spacer/>
                    <v-btn text @click="ui.changeEmail=false">
                        <v-icon left>mdi-close-circle</v-icon>
                        <str index="buttons.cancel"/>
                    </v-btn>
            </v-card-actions>                                
        </v-card>

    </v-dialog>

    <v-dialog v-model="ui.changePassword" persistent width="400" :hide-overlay="true" :retain-focus="true">
        <v-card>
            <v-card-title>
                <str index="authenticator.edit_profile.change_password.title"/>
            </v-card-title>
            <v-card-text style="text-align: center;">
                <v-form ref="changePassword" v-model="validForms.changePassword">
                    <v-text-field type="password" dense outlined v-model="password.new" :label="labels.forms.password._text[language]" required :rules="rules.validPassword">
                        <template v-slot:message="{message, key}">
                            <span v-html="message"/>
                        </template>
                    </v-text-field>
                    <v-text-field type="password" dense outlined v-model="password.confirmation" required @keyup="$refs.changePassword.validate()" :rules="rules.verifyPasswords">
                        <template v-slot:label>
                            <str index="forms.confirm_password"/>
                        </template>
                    </v-text-field>
                </v-form>
                    
                    <str index="authenticator.edit_profile.change_password.text"/>: {{$store.getters.user.attributes.email}}
                    <br>
                    <v-btn class="themed" :disabled="!validForms.changePassword" @click="requestPasswordChange">
                        <str index="buttons.send"/>
                    </v-btn>

                
                    <v-otp-input
                        v-if="validForms.changePassword"
                        v-model="password.pin"
                        type="number"
                        :length="config.pinLength"
                    />            
            </v-card-text>

            <v-card-actions>
                <v-spacer/>
                    <v-btn class="themed" :disabled="password.pin==null || password.pin.length<config.pinLength" @click="verifyPasswordChange">
                        <str index="buttons.submit"/>
                    </v-btn>
                <v-spacer/>
            </v-card-actions> 

            <v-card-actions>
                <v-spacer/>
                    <v-btn text @click="ui.changePassword=false">
                        <v-icon left>mdi-close-circle</v-icon>
                        <str index="buttons.cancel"/>
                    </v-btn>
            </v-card-actions>                                
        </v-card>

    </v-dialog>

    <v-snackbar v-model="ui.snackbar.display">
        <str v-if="ui.snackbar.display && ui.snackbar.message" :index="ui.snackbar.message"/>
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="ui.snarkbar.display = false"
        >
          <str index="buttons.close"/>
        </v-btn>
      </template>
    </v-snackbar>  

    <v-btn icon large style="position: absolute; top: 0px; right: 0px;" @click="$emit('close')">
        <v-icon>
            mdi-close-circle
        </v-icon>
    </v-btn>  
</div>

</template>

<script>
import Bottle from '../assets/background.png'
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from '@//aws-exports';
Amplify.configure(awsconfig);
import validations from '@/plugins/validations.js'

export default {
    created: function(){
        // this.$store.commit('language',this.$store.getters.user.attributes['custom:language'])
        this.temp.language = this.$store.getters.user.attributes['custom:language']
        this.email.new = this.$store.getters.user.attributes.email.slice()
        this.validations = new validations.methods(this)
        this.rules = new validations.rules(this)    
        this.init()
        
        Hub.listen('auth', (data) => {
            const { payload } = data;
            
            if(this.debug){
                console.log('Hub response',payload)
            }
            
            if(payload.event=="forgotPassword"){
                this.ui.snackbar.display = true
                this.ui.snackbar.message = this.strMap.messages.pin_sent //'authenticator.message.pin_sent'
            }

            if(payload.event=='forgotPasswordSubmit_failure'){
                if(payload.data.code=='LimitExceededException'){
                    this.ui.snackbar.display = true
                    this.ui.snackbar.message = this.strMap.errors.attempt_limit_exceeded //'authenticator.errors.attempt_limit_exceeded'

                }

                if(payload.data.code=='ExpiredCodeException'){
                    this.ui.snackbar.display = true
                    this.ui.snackbar.message = this.strMap.errors.expiredCode //'authenticator.errors.expiredCode'
                }
            }

            
        })        
    },
    props: {
        config: {
            type: Object,
            required: true
        },
        labels: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en-ca'
        }
    },
    data: function(){
        return {
            busy: false,
            validations: null,
            rules: null,
            ui: {
                changeEmail: false,
                changePassword: false,
                snackbar: {
                    display: false,
                    message: null
                }
            },
            validForms: {
                moreInfo: false,
                changePassword: false
            },
            email: {
                new: null,
                pin: null
            },
            password: {
                new: null,
                confirmation: null,
                pin: null
            },
            user: null,
            db: {
                countries: [],
                states: [],
                cities: []
            },
            temp: {
                language: null
            }
        }
    },
    methods: {
        init: async function(){
            this.getCountries()
            this.getStates()
            this.getCities()
        },
        getCountries: async function(){
            async function getCountries(){
                let response
                try {
                    response = await self.sendRequest('get','/api/csc/getCountries')    
                } catch (error) {
                    console.error('getCountries error:', error)
                }
                return response.status==200 ? response.data : []
            }

            let self = this

            let getCountriesResponse = await getCountries()
            self.db.countries = getCountriesResponse
        },
        getStates: async function(){
            async function getData(country){
                let response
                try {
                    response = await self.sendRequest('get',`/api/csc/getStates/${country}`)
                } catch (error) {
                    console.error('getStates error', error)                    
                }
                return response.status==200 ? response.data : []
            }

            let self = this
            let country = self.model.country
            if(typeof country=='string' && country.length>0){
                self.db.states = await getData(country)
            }else{
                console.error('getStates error: missing required data', country)
            }
        },
        getCities: async function(){
            async function getData(country, prov_state){
                let response
                try {
                    response = await self.sendRequest('get',`/api/csc/getCities/${country}/${prov_state}`)
                } catch (error) {
                    console.error('getCities error', error)                    
                }
                return response.status==200 ? response.data : []
            }

            let self = this
            let country = self.model.country
            let prov_state = self.model.prov_state
            if(((typeof country=='string' && country.length>0)) && (typeof prov_state=='string' && prov_state.length>0)){
                self.db.cities = await getData(country, prov_state)
            }else{
                console.error('getCities error: missing required data', {country, prov_state})
            }
        },
        changeEmail: async function(){
            let self = this
            async function requestEmailChange(){
                let test = self.email.new != self.$store.getters.user.attributes.email
                if(test){
                    let user = await Auth.currentAuthenticatedUser();
                    try{
                        self.user = await Auth.updateUserAttributes(user, {
                            'email': self.email.new
                        });
                    }catch(err){
                        console.error('Error requestEmailChange',err)
                    }
                }else{
                    console.error('This is the same email on record')
                }
            }
            self.busy = true
            await requestEmailChange()
            self.ui.changeEmail = self.user!=null
            self.busy = false
        },
        verifyEmailChange: async function(){
            let self = this
            self.ui.snackbar.display = true
            try{
                let result = await Auth.verifyCurrentUserAttributeSubmit('email', self.email.pin)
                self.ui.snackbar.message = self.strMap.messages.change_email//"authenticator.edit_profile.message.change_email_success"
                setTimeout(function(){
                    self.$emit('signOut')
                },3000)
            }catch(err){
                console.error('Error verifyEmailChange',err)
                self.ui.snackbar.message = self.strMap.errors.change_email//"authenticator.edit_profile.message.change_email_failed"
            }
        },
        requestPasswordChange: async function(){
            let username = this.username
            let response = await Auth.forgotPassword(username)
        },
        verifyPasswordChange: async function(){
            let self = this
            let username = this.username
            let code = this.password.pin
            let new_password = this.password.new

            let response = await Auth.forgotPasswordSubmit(username, code, new_password)
            if(response=='SUCCESS'){
                this.password.new = null
                this.password.confirmation = null
                this.password.pin = null
                this.ui.changePassword = false
                self.ui.snackbar.message = self.strMap.messages.change_password //"authenticator.edit_profile.message.change_password_success"
            }else{

                self.ui.snackbar.message = self.strMap.errors.change_password //"authenticator.edit_profile.message.change_password_failed"
            }
            self.ui.snackbar.display = true

        }
    },
    computed: {
        username: function(){
            return this.$store.getters.user.attributes.email
        },
        model: function(){
            return this.$attrs.value
        },
        fields: function(){
            let fields = [
                // {
                //     target: 'given_name',
                //     label: 'forms.given_name',
                //     required: true,
                //     rules: this.rules.required,
                //     type: 'textfield'
                // },
                // {
                //     target: 'family_name',
                //     label: 'forms.family_name',
                //     required: true,
                //     rules: this.rules.required,
                //     type: 'textfield'
                // },
                {
                    target: 'practice_setting',
                    label: 'forms.field.practice_setting',
                    required: false,
                    rules: this.rules.required,
                    type: 'select',
                    items: this.practice_setting
                },
                {
                    target: 'practice_years',
                    label: 'forms.field.practice_years',
                    required: false,
                    rules: this.rules.isNumber,
                    type: 'number'
                },
                {
                    target: 'country',
                    label: 'forms.country',
                    required: false,
                    rules: [],
                    type: 'autocomplete',
                    items: this.countries
                },
                {
                    target: 'prov_state',
                    label: 'forms.province',
                    required: false,
                    rules: [],
                    type: 'autocomplete',
                    items: this.states
                },
                // {
                //     target: 'city',
                //     label: 'forms.city',
                //     required: false,
                //     rules: [],
                //     type: 'autocomplete',
                //     items: this.cities
                // },
                // {
                //     target: 'address',
                //     label: 'forms.address',
                //     required: false,
                //     rules: [],
                //     type: 'textfield'
                // },
                // {
                //     target: 'postal_zip',
                //     label: 'forms.postal_code',
                //     required: false,
                //     rules: [],
                //     type: 'textfield'
                // },
                {
                    target: 'profession',
                    label: 'forms.field.profession',
                    required: false,
                    rules: this.rules.required,
                    type: 'select',
                    items: this.professions
                },
                {
                    target: 'specialty',
                    label: 'forms.field.specialty',
                    required: false,
                    rules: this.rules.required,
                    type: 'select',
                    items: this.specialties
                },           
                {
                    target: 'ctc_casl',
                    label: 'forms.casl',
                    required: false,
                    rules: [],
                    type: 'casl'
                }
            ]

            return fields
        },
        csc: function(){
            return {
                country: this.model.country,
                prov_state: this.model.prov_state,
                city: this.model.city
            }
        },
        countries: function(){
            let list = []
            let countries = this.db.countries
            for(let i=0; i<countries.length; i++){
                let country = countries[i]
                list.push({
                    text: country.name,
                    value: country.code
                })
            }
            return list
        },
        states: function(){
            let list = []
            let states = this.db.states
            for(let i=0; i<states.length; i++){
                let state = states[i]
                list.push({
                    text: state.name,
                    value: state.code
                })
            }
            return list
        },
        cities: function(){
            let list = []
            let cities = this.db.cities
            for(let i=0; i<cities.length; i++){
                let city = cities[i]
                list.push({
                    text: city.name,
                    value: city.name
                })
            }
            list.push({
                text: this.$store.getters.language=='fr-ca' ? 'Non listé' : 'Not listed',
                value: 'not_listed'
            })

            return list
        },
        professions: function(){
            let labels = this.labels
            let language = this.language

            let list = [
                {
                    text: labels?.forms?.profession?.physician?._text[language] ? labels.forms.profession.physician._text[language] : 'MISSING - labels.forms.profession.physician[language]',
                    value: 'physician'
                }
            ]


            return list
        },
        specialties: function(){
            let labels = this.labels
            let language = this.language

            let list = [
                {
                    text: labels?.forms?.profession?.dermatologist?._text[language] ? labels.forms.profession.dermatologist._text[language] : 'MISSING - labels.forms.profession.dermatologist[language]',
                    value: 'dermatologist'
                }
            ]


            return list
        },
        practice_setting: function(){
            let labels = this.labels
            let language = this.language

            let list = [
                {
                    text: labels?.forms?.practice_setting?.community?._text[language] ? labels.forms.practice_setting.community._text[language] : 'MISSING - labels.forms.practice_setting.community[language]',
                    value: 'community'
                },
                {
                    text: labels?.forms?.practice_setting?.hospital?._text[language] ? labels.forms.practice_setting.hospital._text[language] : 'MISSING - labels.forms.practice_setting.hospital[language]',
                    value: 'hospital'
                },
                {
                    text: labels?.forms?.practice_setting?.academia?._text[language] ? labels.forms.practice_setting.academia._text[language] : 'MISSING - labels.forms.practice_setting.academia[language]',
                    value: 'academia'
                }
            ]


            return list
        },
        images: function(){
            return {
                Bottle
            }
        },
        strMap: function(){
            return {
                'messages': {
                    'pin_sent': 'authenticator.messages.pin_sent',
                    'change_email': "authenticator.edit_profile.messages.change_email",
                    'change_password': "authenticator.edit_profile.messages.change_password",
                },
                'errors': {
                    'attempt_limit_exceeded': 'authenticator.errors.attempt_limit_exceeded',
                    'expiredCode': 'authenticator.errors.expiredCode',
                    'change_email': "authenticator.edit_profile.errors.change_email",
                    'change_password': "authenticator.edit_profile.errors.change_password"
                }
            }
        }
    },
    watch: {
        csc: {
            deep: true,
            handler: function(after, before){
                if(after.country!=before.country){
                    this.getStates()
                }
                if(after.prov_state!=before.prov_state){
                    this.getCities()
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
.decorative-column{
    background-color: whitesmoke;
}
.v-btn.primary{
    background-color: $primary-light-1 !important;
}
</style>